import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

import {cloneDeep, flatten, filter} from 'lodash';

import AccordionElement from "../components/accordion/accordion_element";
import Accordion from "../components/accordion/accordion";


// Besucherinfo Page Component
const Besucherinfos = props => {

    const language_id = 1;

    const visitorInfos = filter(props.data.allVisitorInfos.nodes, function(info){ return info.language_id == language_id})[0];

    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="Besucherinfos" />

           
            <section className="user_infos_section">
                <div className="user_infos_wrapper main_content_wrapper">
                    <h1 lang="de">{visitorInfos.title}</h1>

                    <div
                        className="welcome_description"
                        dangerouslySetInnerHTML={{
                            __html: visitorInfos.description
                        }}
                    ></div>

                    <div className="app_download_wrapper">
                        <a
                            href={visitorInfos.app_link_ios}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ios_btn"
                        >
                            <img
                                src="../images/app_store.svg"
                                alt="Get Virtulleum in Apple App Store Icon"
                            />
                        </a>
                        <a
                            href={visitorInfos.app_link_android}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="android_btn"
                        >
                            <img
                                src="../images/play_store.svg"
                                alt="Get Virtulleum in Google Play Store Icon"
                            />
                        </a>
                    </div>
                </div>

                <div className="main_content_leaf_deco_1"></div>
                <div className="main_content_leaf_deco_2"></div>
            </section>

        </Layout>
    );
};

export default Besucherinfos

export const query = graphql`
    query MyQueryEn {
        allVisitorInfos {
            nodes {
                app_link_android
                app_link_ios
                description
                language_id
                title
            }
        }
    }
`
  